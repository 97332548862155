import type { PipedriveParams } from "@/backend/types";
import { getURLObject } from "./getURLObject";

/**
 * Converts search parameters to object
 * @param params
 * @returns {PipedriveParams | null}
 */
export function searchParamsToObject(
  params: string,
  fakeJwt: string | null
): null | PipedriveParams {
  let searchParams = new URLSearchParams(params);
  if (fakeJwt && !searchParams.get("token")) {
    const url = getURLObject();
    if (url) {
      url.searchParams.set("token", fakeJwt);
      window.location.href = url.href;
    }
  }

  try {
    let searchParams = new URLSearchParams(params);

    let main_params = Object.fromEntries([...searchParams]) as PipedriveParams;
    if (main_params.data) {
      let data_params = JSON.parse(main_params.data);
      if (data_params.domain_id) {
        main_params.domain_id = data_params.domain_id;
      }
    }

    if (!Object.keys(main_params).length) {
      return null;
    }

    return main_params;
  } catch {
    return null;
  }
}
