import AppExtensionsSDK, {
  Command,
  Event,
  Modal,
} from "@pipedrive/app-extensions-sdk";

import { searchParamsToObject } from "@/client/utils/searchParamsToObject";

const CARD_HEIGHT = 58;
export const FOOTER_HEIGHT = 51;
const MAX_HEIGHT = 500;
export const MIN_HEIGHT = 2 * CARD_HEIGHT + FOOTER_HEIGHT;

export class SurfaceAppSdk {
  sdk: AppExtensionsSDK | undefined;
  isDev: boolean;
  visible: boolean;
  listener: undefined | (() => void);
  date: Date;

  constructor() {
    const isCypress = process.env.IS_CYPRESS === "true";
    this.isDev = isCypress;
    this.visible = false;
    this.date = new Date();
  }

  async init(
    isDev: boolean,
    fakeJwt: null | string,
    size: number | null | undefined | { w: number; h: number }
  ) {
    if (!isDev) {
      const sdk = await new AppExtensionsSDK().initialize({
        size:
          typeof size === "object" && size
            ? { height: size.h, width: size.w }
            : { height: getHeightByHeightValue(size ?? 0) },
      });

      sdk.listen(Event.VISIBILITY, ({ error, data }) => {
        if (data) {
          const { is_visible } = data;
          this.visible = is_visible;
        }
      });
      this.sdk = sdk;
    } else {
      searchParamsToObject(document.location.search, fakeJwt ?? null);
    }

    this.visible = true;
    this.isDev = isDev;
  }

  async getUserTheme() {
    if (!this.sdk) return;

    return this.sdk.userSettings;
  }

  async resizeByHeight(height: number) {
    if (!this.sdk) return;

    await this.sdk.execute(Command.RESIZE, {
      height: getHeightByHeightValue(height),
    });
  }

  listen(
    name: "settings" | "shareFeedback" | "interface",
    callback: () => void
  ) {
    if (!this.sdk) return;

    const listener = this.sdk.listen(
      Event.CLOSE_CUSTOM_MODAL,
      ({ error, data }) => {
        callback();
      }
    );

    this.listener = listener;
  }

  setSnackbar(message: string) {
    const sdk = this.sdk;
    const isDev = this.isDev;
    if (sdk && !isDev) {
      try {
        sdk.execute(Command.SHOW_SNACKBAR, {
          message,
        });
      } catch (error) {
        console.error("➡️ setSnackbar error: ", error);
      }
    }
  }

  async openShareFeedbackModal() {
    const sdk = this.sdk;
    if (!sdk) return;

    const actionId = process.env.NEXT_PUBLIC_SHARE_FEEDBACK_ACTION_ID;

    if (!actionId || actionId === "_") {
      throw new Error("NEXT_PUBLIC_SHARE_FEEDBACK_ACTION_ID missing");
    }

    await sdk.execute(Command.OPEN_MODAL, {
      type: Modal.CUSTOM_MODAL,
      action_id: actionId,
    });
  }

  async openSettingsModal(callBackFn: () => void) {
    const sdk = this.sdk;
    if (!sdk) return;
    this.listen("settings", () => {
      console.log("close... ");
      if (this.listener) {
        callBackFn(); //fetch new domains
        this.listener(); //stop listening
      }
    });
    const actionId = process.env.NEXT_PUBLIC_ACTION_ID;

    if (!actionId || actionId === "_") {
      throw new Error("NEXT_PUBLIC_ACTION_ID missing");
    }

    await sdk.execute(Command.OPEN_MODAL, {
      type: Modal.CUSTOM_MODAL,
      action_id: actionId,
    });
  }
  async openInterfaceModal(
    data: { eventId: string; w: string; h: string; domainId: string },
    callBackFn: () => void
  ) {
    const sdk = this.sdk;
    if (!sdk) return;
    this.listen("interface", () => {
      console.log("close interface... ");
      if (this.listener) {
        callBackFn(); //fetch new domains
        this.listener(); //stop listening
      }
    });
    const actionId = process.env.NEXT_PUBLIC_INTERFACE_ACTION_ID;

    if (!actionId || actionId === "_") {
      throw new Error("NEXT_PUBLIC_INTERFACE_ACTION_ID missing");
    }

    await sdk.execute(Command.OPEN_MODAL, {
      type: Modal.CUSTOM_MODAL,
      action_id: actionId,
      data,
    });
  }
  async closeModal() {
    const sdk = this.sdk;
    if (!sdk) return;
    await sdk.execute(Command.CLOSE_MODAL);
  }
}

export function getHeightByHeightValue(height: number) {
  if (height < MIN_HEIGHT) {
    return MIN_HEIGHT;
  } else if (height > MAX_HEIGHT) {
    return MAX_HEIGHT;
  } else {
    return height;
  }
}
export function isMinOrMAXReached(height: number) {
  if (height < MIN_HEIGHT) {
    return "min";
  } else if (height > MAX_HEIGHT) {
    return "max";
  } else {
    return "none";
  }
}

export const surfaceAppSdkService = new SurfaceAppSdk();
