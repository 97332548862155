import styled, { css } from "styled-components";
import React from "react";
import { isInIFrame } from "@/client/utils/isInIFrame";

const StyledPage = styled.div<{
  inIFrame: boolean;
  useMediumBackgroundColor: boolean;
}>`
  background-color: ${(props) =>
    props.inIFrame
      ? props.theme.colors.fill.base
      : props.theme.colors.fill.medium};
  height: 100vh;

  ${({ useMediumBackgroundColor }) =>
    useMediumBackgroundColor &&
    css`
      background-color: ${(props) => props.theme.colors.fill.medium};
    `}
`;

export default function Page(props: {
  children: React.ReactNode | React.ReactNode[];
  useMediumBackgroundColor?: boolean;
}): JSX.Element {
  const is_in_iframe = isInIFrame();

  return (
    <StyledPage
      id="Page"
      inIFrame={is_in_iframe}
      useMediumBackgroundColor={Boolean(props.useMediumBackgroundColor)}
    >
      {props.children}
    </StyledPage>
  );
}
