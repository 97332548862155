import {
  getCustomFooterContent,
  getIsDev,
  getJwt,
  toErrorPath,
} from "../application/module/utils";

import Button from "@/client/components/Button";
import { Codes } from "@/backend/utils";
import Feedback from "@/client/components/Feedback";
import { GetServerSideProps } from "next";
import MainFooter from "@/client/components/MainFooter";
import Page from "@/client/components/Page";
import PageWrapper from "@/client/components/PageWrapper";
import type { PipedriveParams } from "@/backend/types";
import { getFakeJwt } from "@/backend/utils/fakeJwt";
import { getOrganizationByCompanyIdForErrorPage } from "@/backend/db/organization";
import { searchParamsToObject } from "@/client/utils/searchParamsToObject";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import styled from "styled-components";
import { surfaceAppSdkService } from "@/lib/pipedriveSurface";
import to from "await-to-js";
import { useEffectOnce } from "usehooks-ts";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { z } from "zod";

type Props = {
  customFooter: ReturnType<typeof getCustomFooterContent>;
  isDev: boolean;
  isMaybeAdmin: boolean;
  fakeJwt: string;
};
export default function ErrorPage({
  customFooter,
  isDev,
  isMaybeAdmin,
  fakeJwt,
}: Props): JSX.Element {
  const { t } = useTranslation(["errors"]);

  const [serverServedSearchParams, setServerServedSearchParams] =
    useState<PipedriveParams | null>(null);

  useEffectOnce(() => {
    const temp = searchParamsToObject(window.location.search, null);

    setServerServedSearchParams(temp);

    (async function () {
      if (!isDev) {
        await surfaceAppSdkService.init(isDev, null, 300);
      }
    })();
  });

  const text = `errors.${serverServedSearchParams?.error}.label`;

  const isExists = t(text) !== text;

  const devContent = (
    <StyledButtonWrapper>
      <Button
        onClick={() => {
          const url = new URL(window.location.href);
          url.pathname = "";
          if (!url.searchParams.has("selectedIds")) {
            url.searchParams.append("selectedIds", "1995");
          }
          if (!url.searchParams.has("companyId")) {
            url.searchParams.append("companyId", "7393770");
          }
          if (!url.searchParams.has("userId")) {
            url.searchParams.append("userId", "11257768");
          }
          if (!url.searchParams.has("resource")) {
            url.searchParams.append("resource", "deal");
          }
          window.location.href = url.href;
        }}
      >
        Go Main
      </Button>
      <Button
        onClick={() => {
          const url = new URL(window.location.href);
          url.pathname = "interface";
          if (!url.searchParams.has("selectedIds")) {
            url.searchParams.append("selectedIds", "1995");
          }
          if (!url.searchParams.has("companyId")) {
            url.searchParams.append("companyId", "7393770");
          }
          if (!url.searchParams.has("userId")) {
            url.searchParams.append("userId", "11257768");
          }
          if (!url.searchParams.has("resource")) {
            url.searchParams.append("resource", "deal");
          }
          window.location.href = url.href;
        }}
      >
        Go Interface
      </Button>
      <Button
        onClick={() => {
          const url = new URL(window.location.href);
          url.pathname = "settings";
          window.location.href = url.href;
        }}
      >
        Go Settings
      </Button>
      <Button
        variant="negative"
        onClick={() => {
          const url = new URL(window.location.href);
          url.searchParams.delete("token");
          window.location.href = url.href;
        }}
      >
        delete token
      </Button>
    </StyledButtonWrapper>
  );

  if (isUrl(serverServedSearchParams?.error)) {
    return (
      <Page>
        <PageWrapper>
          <Feedback
            type={"Error"}
            headerText={t(`errors.no_refresh_token.label`)}
            message={t(`errors.no_refresh_token.description`)}
          >
            <Button
              onClick={() => {
                window.open(serverServedSearchParams?.error);
              }}
            >
              <span>{t(`errors.no_refresh_token.action`)}</span>
            </Button>
          </Feedback>
          <a
            href={t("errors.no_refresh_token.url") ?? undefined}
            target={"_blank"}
            rel="noreferrer"
          >
            {t("errors.no_refresh_token.url_label")}
          </a>
          {isDev && <>{devContent}</>}
        </PageWrapper>
        <MainFooter
          customFooter={customFooter}
          isDev={false}
          isMaybeAdmin={isMaybeAdmin}
        />
      </Page>
    );
  } else if (!isExists) {
    return (
      <Page>
        <PageWrapper>
          <Feedback
            type={"Error"}
            headerText={t(`errors.message.header_text`)}
            message={serverServedSearchParams?.error}
          />
          {isDev && <>{devContent}</>}
        </PageWrapper>
        <MainFooter
          customFooter={customFooter}
          isDev={false}
          isMaybeAdmin={isMaybeAdmin}
        />
      </Page>
    );
  }

  return (
    <Page>
      <PageWrapper>
        <Feedback
          type={"Error"}
          headerText={t(`errors.${serverServedSearchParams?.error}.label`)}
          message={t(`errors.${serverServedSearchParams?.error}.description`)}
        />
        {isDev && <>{devContent}</>}
      </PageWrapper>
      <MainFooter
        customFooter={customFooter}
        isDev={false}
        isMaybeAdmin={isMaybeAdmin}
      />
    </Page>
  );
}

const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const querySchema = z.object({
  token: z.string().min(1),
  count: z.preprocess((val) => {
    if (!val && typeof val !== "number") {
      return 0;
    }
    return Number(val);
  }, z.number()),
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  const query = querySchema.safeParse(context.query);
  const isDev = getIsDev(context.req);
  if (query.success && query.data.count === 0) {
    const fakeJwt = getFakeJwt();
    const jwt = await getJwt(fakeJwt, query.data.token);

    const isJwtError = (await import("@/lib/jwt")).isJwtError;

    if (!jwt || isJwtError(jwt)) {
      return toErrorPath(
        isDev,
        { ...context.query, count: "1" },
        Codes.REFRESH_JWT_TOKEN
      );
    }

    try {
      const [err, organization] = await to(
        getOrganizationByCompanyIdForErrorPage(Number(jwt.companyId))
      );

      if (err) {
        return toErrorPath(
          isDev,
          { ...context.query, count: "1" },
          Codes.NO_ORGANIZATION
        );
      }

      const customFooter = getCustomFooterContent(
        organization,
        organization.partner
      );

      const currentUser = organization.installations.find(
        (item) => String(item.user_id) === String(jwt.userId)
      );

      if (!currentUser) {
        return toErrorPath(
          isDev,
          { ...context.query, count: "1" },
          Codes.USER_NOT_FOUND
        );
      }

      const isMaybeAdmin = currentUser
        ? Boolean(currentUser.refresh_token)
        : false;

      return {
        props: {
          isMaybeAdmin,
          customFooter,
          ...(await serverSideTranslations("en", ["errors", "main"])),
        },
      };
    } catch (error) {
      return {
        props: {
          isMaybeAdmin: false,
          isDev,
          ...(await serverSideTranslations("en", ["errors", "main"])),
        },
      };
    }
  }

  return {
    props: {
      isMaybeAdmin: false,
      isDev,
      ...(await serverSideTranslations("en", ["errors", "main"])),
    },
  };
};

function isUrl(val?: string | null) {
  if (!val) return false;
  try {
    new URL(val);
    return true;
  } catch (error) {
    return false;
  }
}
