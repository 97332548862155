import styled from "styled-components";
import React from "react";
import { isInIFrame } from "@/client/utils/isInIFrame";

const StyledWrapper = styled.div<{ inIFrame: boolean }>`
  max-width: 600px;
  border-radius: 6px;
  top: ${(props) => (props.inIFrame ? 0 : 3)}rem;
  position: relative;
  margin: auto;
  padding: ${(props) => (props.inIFrame ? 1 : 2)}rem;
  background-color: ${(props) => props.theme.colors.fill.base};
  text-align: ${(props) => (props.inIFrame ? "center" : "auto")};
  /* overflow-y: scroll; */
`;

export default function PageWrapper(props: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  const is_in_iframe = isInIFrame();

  return (
    <StyledWrapper inIFrame={is_in_iframe}>{props.children}</StyledWrapper>
  );
}
