export function getURLObject() {
  try {
    const url = new URL(window.location.href);
    return url;
  } catch (error) {
    console.log("➡️ getURLObject error: ", error);

    return null;
  }
}
